import { createContext, useState, useEffect } from 'react';
import { IAuthProvider, IContext, IUser } from './types';
import { executeRequestDelete, executeRequestGet, executeRequestPost, getUserLocalStorage, LoginRequest, setUserLocalStorage } from './util';
import { CONSULTA_MASTER, CONSULTA_USUARIO } from '../../routes_api';

export const AuthContext = createContext<IContext>({} as IContext);

export const AuthProvider = ({ children }: IAuthProvider) => {
    const [user, setUser] = useState<IUser | null>();

    useEffect(() => {
        const user = getUserLocalStorage()

        if (user) {
            setUser(user);
        }
    }, []);

    async function authenticate(username: string, password: string) {
        const response = await LoginRequest(username, password)

        if (response.status && ((response.status === 200) || (response.status === 201))) {
            const payload = {
                id: response.dados.id,
                token: response.dados.token,
                name: response.dados.nome,
                diasRestantes: response.dados.diasVencerAcesso,
                privilegio: response.dados.privilegio,
                masterId: response.dados.masterContador,
                usuarioId: response.dados.usuarioContador
            }

            setUser(payload);
            setUserLocalStorage(payload);
        } else
            throw response;
    }

    async function alterUser(_user: any) {
        setUser(_user);
        setUserLocalStorage(_user);
    }

    async function refreshMaster() {
        var response: any = {};

        response = await executeRequestGet(CONSULTA_MASTER, user?.token);

        if (response.status && ((response.status === 200) || (response.status === 201))) {
            const master: any = response.data.dados;

            response = await executeRequestGet(CONSULTA_USUARIO, user?.token);

            if (response.status && ((response.status === 200) || (response.status === 201))) {
                const usuario: any = response.data.dados;

                const _user: any = getUserLocalStorage();

                const payload = {
                    id: _user.id,
                    token: _user.token,
                    name: usuario.nome,
                    diasRestantes: master.diasFaltantes,
                    privilegio: master.privilegio,
                    masterId: master.masterContador,
                    usuarioId: master.usuarioContador
                }

                setUser(payload);
                setUserLocalStorage(payload);
            } else
                throw response;
        } else
            throw response;
    }

    async function requestGet(url: string) {
        const response = await executeRequestGet(url, user?.token);

        if ((response.response) && (response.response.status === 406)) {
            logout();
            return response
        }

        if (response.status && ((response.status === 200) || (response.status === 201))) {
            return response
        } else
            throw response;
    }

    async function requestPost(url: string, data: any) {
        const response: any = await executeRequestPost(url, user?.token, data);

        if (response.status && ((response.status === 200) || (response.status === 201))) {
            return response
        } else
            throw response;
    }

    async function requestDelete(url: string, data: any) {
        const response: any = await executeRequestDelete(url, user?.token, data);

        if (response.status && ((response.status === 200) || (response.status === 201))) {
            return response
        } else
            throw response;
    }

    function logout() {
        setUser(null);
        setUserLocalStorage(null);
    }

    return (
        <AuthContext.Provider value={{ ...user, authenticate, logout, requestGet, requestPost, requestDelete, refreshMaster, alterUser }}>
            {children}
        </AuthContext.Provider>
    )
}