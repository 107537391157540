import { forwardRef, useImperativeHandle, useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, useTheme } from "@mui/material";
import { BsLoadingButton } from "../../components/BsLoadingButton";
import { COLOR_THEME } from "../../styles/theme";
import styled from "styled-components";

export const Icon = styled.div`
    display: flex;
    align-items: center;
    margin-right: 6px;
`
export const Title = styled.div`
    display: flex;
    align-items: center;
    font-weight: 500;
`
const DialogTytleStyled = styled(DialogTitle)`
    background-color: transparent;
    height: 65px;
    align-items: center; 
    display: flex;
`
const DialogActionsStyled = styled(DialogActions)`
    height: 65px;
    padding-right: 20px !important;
`

export const Modal = forwardRef((props: any, ref) => {
    const theme = useTheme();

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    function openModal() {
        setOpen(true);
    }

    function closeModal() {
        setOpen(false);
    }

    useImperativeHandle(ref, () => ({
        openModal,
        closeModal,
        setLoading
    }));

    return (
        <Dialog
            open={props.open || open}
            fullWidth
            sx={{ ...props.sx, margin: '0 !important', width: '100% !important' }}
            maxWidth={props.maxWidth}
        >
            <DialogTytleStyled sx={{ backgroundColor: theme.palette.mode === 'dark' ? '#181818' : 'white' }}>
                <Icon>{props?.icon}</Icon>
                <Title>{props.title}</Title>
            </DialogTytleStyled>

            <Divider variant="middle" />

            <fieldset disabled={loading} >
                <DialogContent sx={{ paddingTop: '10px !important' }}>{props.children}</DialogContent>
                {
                    !props.invisibleActions &&
                    <>
                        <Divider variant="middle" />

                        <DialogActionsStyled>
                            <BsLoadingButton
                                loading={loading}
                                onClick={() => props?.cancelClick ? props?.cancelClick() : closeModal()}>
                                {props.labelCancel ? props.labelCancel : 'Cancelar'}
                            </BsLoadingButton>
                            {props.confirmClick &&
                                <BsLoadingButton
                                    loading={loading}
                                    onClick={props?.confirmClick}
                                    style={{ backgroundColor: COLOR_THEME }}
                                >
                                    {props.labelConfirm ? props.labelConfirm : 'Salvar'}
                                </BsLoadingButton>
                            }
                            {props.submit &&
                                <BsLoadingButton
                                    form={props.submit}
                                    loading={loading}
                                    type="submit"
                                    style={{ backgroundColor: COLOR_THEME }}
                                >
                                    {props.labelSubmit ? props.labelSubmit : 'Salvar'}
                                </BsLoadingButton>
                            }
                        </DialogActionsStyled>
                    </>
                }
            </fieldset >
        </Dialog>
    )
})