import { Button as ButtonBootstrap, ButtonProps as ButtonPropsBootstrap } from 'react-bootstrap';
import styled from 'styled-components';
import { lighten } from 'polished';
import { COLOR_THEME } from '../../styles/theme';

const Icon = styled.div`
    display: flex;
    margin-right: 5px;
    pointer-events: none;
    background-color: transparent;
`

interface ButtonProps extends ButtonPropsBootstrap {
    icon?: any;
    color?: any;
    iconcenter?: any;
    visible?: boolean;
    disabled?: boolean;
}

export const Button = (props: ButtonProps) => {
    const _color = props.color ? props.color : COLOR_THEME;

    if (props.visible === false) {
        return null;
    }

    return (
        <ButtonBootstrap
            disabled={props?.disabled}
            size={props.size ? props.size : 'sm'}
            {...props}
            style={{
                ...props.style,
                backgroundColor: _color,
                borderColor: _color,
                fontWeight: 600,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}
            onFocus={(e: any) => {
                e.target.style.boxShadow = `0 0 0 0.05rem ${_color}80, 0 0 0 0.1rem ${_color}40`;
            }}
            onBlur={(e: any) => {
                e.target.style.boxShadow = 'none';
            }}
            onMouseDown={(e: any) => {
                e.target.style.backgroundColor = lighten(0.07, _color);
                e.target.style.borderColor = lighten(0.07, _color);
            }}
            onMouseUp={(e: any) => {
                e.target.style.backgroundColor = _color;
                e.target.style.borderColor = _color;
            }}
        >
            {props.icon &&
                <Icon> {props.icon} </Icon>
            }
            {props.iconcenter &&
                <Icon style={{ margin: 0 }}> {props.iconcenter} </Icon>
            }
            {props.children}
        </ButtonBootstrap>
    )
}

export const ButtonDataTable = styled(Button)`
    font-size: 22px;
    padding: 2px;
    margin: 0px 4px;
    width: 32px;
    height: 32px;
    border-radius: 6px;
`