import React, { useRef, useState } from "react";
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ScheduleOutlinedIcon from '@mui/icons-material/ScheduleOutlined';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import YouTubeIcon from '@mui/icons-material/YouTube';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import CampaignIcon from '@mui/icons-material/Campaign';
import GroupsIcon from '@mui/icons-material/Groups';
import StackedBarChartOutlinedIcon from '@mui/icons-material/StackedBarChartOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { PRIVILEGIO_ADM, PRIVILEGIO_NORMAL, PRIVILEGIO_REVENDA } from "../../../contexts/AuthProvider/RequireAuth";
import { Divider, IconButton, List, ListItemButton, Typography } from "@mui/material";
import { COLOR_THEME, COLOR_THEME_FONT } from "../../../styles/theme";
import { useAuth } from "../../../contexts/AuthProvider/useAuth";
import { Link } from "react-router-dom";
import { BsListItemIcon } from "../../../components/BsListItemIcon";
import { BsListItemText } from "../../../components/BsListItemText";
import Collapse from '@mui/material/Collapse';
import styled from "styled-components";
import { DRAWER_WIDTH } from "../../../components/BsDrawer";
import { BsToolBarLogo } from "../../../components/BsTollbarLogo";
import { MobVersion } from "../../../utils";
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import moment from "moment";
import { FaRobot } from "react-icons/fa";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { RenovacaoPainel } from "../../Comuns/Renovacao";
import SwipeUpIcon from '@mui/icons-material/SwipeUp';

const rotas = [
    { label: 'Dashboard', to: '/', icon: <StackedBarChartOutlinedIcon />, privilegio: PRIVILEGIO_NORMAL },
    {
        label: 'Cadastros', icon: <AddCircleOutlineOutlinedIcon />, privilegio: PRIVILEGIO_NORMAL,
        children: [
            { label: 'Cliente', to: '/clientes' },
            { label: 'Gestão Serviço', to: '/gestaoservicos' },
            { label: 'Serviço', to: '/servicos' },
            { label: 'Tipo Serviço', to: '/tiposservicos' }
        ],
    },
    {
        label: 'WhatsApp', icon: <WhatsAppIcon />,
        children: [
            { label: 'Instâncias', to: '/whatsapp/instancias' },
            { label: 'Template Mensagem', to: '/whatsapp/templatesmensagem' },
            { label: 'Disparos', to: '/whatsapp/disparos' }
        ],
    },
    {
        label: 'Assistente Virtual', icon: <FaRobot />,
        children: [
            { label: 'Assistente', to: '/assist_virtual/assistente' },
            { label: 'Atendimento', to: '/assist_virtual/atendimento' }
        ]
    },
    {
        label: 'Financeiro', icon: <MonetizationOnOutlinedIcon />, privilegio: PRIVILEGIO_NORMAL,
        children: [
            { label: 'Contas Pagar', to: '/contaspagar' },
            { label: 'Contas Receber', to: '/contasreceber' },
            { label: 'Movimentação', to: '/movimentofinanceiro' }
        ]
    },
    {
        label: 'Publicidade', icon: <CampaignIcon />,
        children: [
            { label: 'Contato Avulso', to: '/publicidade/contatosavulsos' },
            { label: 'Lista Transmissão', to: '/publicidade/listatransmissao' },
        ],
    },
    {
        label: 'Histórico', icon: <ScheduleOutlinedIcon />, privilegio: PRIVILEGIO_NORMAL,
        children: [
            { label: 'Log Atividade', to: '/masterlogs' },
            { label: 'Renovações', to: '/renovacoes' }
        ]
    },
    {
        label: 'Revenda', icon: <GroupsIcon />, privilegio: PRIVILEGIO_REVENDA,
        children: [
            { label: 'Master', to: '/revendas/masters' }
        ]
    },
    {
        label: 'Administrativo', icon: <AdminPanelSettingsIcon />, privilegio: PRIVILEGIO_ADM,
        children: [
            { label: 'Revenda', to: '/administrativo/revenda' }
        ]
    },
    { label: 'Indique e Ganhe', to: '/indicacoes', icon: <SwipeUpIcon /> },
    { label: 'Renovar Painel', to: '/planosrenovacao', icon: <AttachMoneyIcon /> },
];

const rotasExtras = [
    { label: 'Central de Ajuda', icon: <ContactSupportOutlinedIcon />, href: 'https://bitsafira.atlassian.net/servicedesk/customer/portals' },
    { label: 'Tutorial Plataforma', icon: <YouTubeIcon />, href: 'https://www.youtube.com/playlist?list=PLmEtmIddEs7tqKCsfpJ8kjjv6HAgRkY9i' },
    { label: 'Status Serviços', icon: <SignalCellularAltIcon />, href: 'https://uptime.bitsafira.com.br/status/bitsafira/' }
];

const BsDivider = () => {
    return (
        <Divider variant='middle' style={{ backgroundColor: 'rgba(255, 255, 255, 0.4)' }} />
    )
}

const ButtonRetrair = (props: any) => {
    return (
        <>
            {
                MobVersion() &&
                <IconButton color="inherit" edge="end" sx={{ ml: 2 }} onClick={() => props?.onClick && props.onClick()}>
                    <CloseIcon />
                </IconButton>
            }
        </>
    )
}

const BsFooterCopyright = styled.footer`
    bottom: 0;
    width: ${DRAWER_WIDTH};
    background-color: ${COLOR_THEME};
    color: ${COLOR_THEME_FONT};
    text-align: center;
    margin: 20px 0 20px 0;
`

interface OpenState {
    [key: string]: boolean;
}

export const SideBar = (props: any) => {
    const auth = useAuth();
    const renovarref = useRef<any>();

    const [openSubItem, setOpenSubItem] = useState<OpenState>({ Cadastros: true });

    const handleClick = (label: any, to: any) => {
        props?.clickItemMenu && props.clickItemMenu(to);
        setOpenSubItem({ ...openSubItem, [label]: !openSubItem[label] });
    };

    const RotasExtras = () => {
        return (
            <List>
                {rotasExtras.map((route, index) => (
                    <React.Fragment key={index}>
                        <ListItemButton href={route.href} target="_blank">
                            <BsListItemIcon icon={route.icon} />
                            <BsListItemText label={route.label} />
                        </ListItemButton>
                    </React.Fragment>
                ))}
            </List>
        )
    }

    return (
        <>
            <BsToolBarLogo > <ButtonRetrair onClick={props.clickExpanded} /> </BsToolBarLogo>
            <List>
                {rotas.map((rota: any, index: number) => (
                    ((!rota.privilegio) || rota.privilegio.includes(auth.privilegio)) &&
                    <React.Fragment key={index}>

                        <ListItemButton component={rota.to && Link} to={rota.to} onClick={() => handleClick(rota.label, rota.to)} >
                            <BsListItemIcon icon={rota.icon} />
                            <BsListItemText label={rota.label} />

                            {
                                rota.children ? openSubItem[rota.label] ? <ExpandMoreOutlinedIcon sx={{ fontSize: 20 }} /> : <KeyboardArrowRightOutlinedIcon sx={{ fontSize: 20 }} /> : <></>
                            }
                        </ListItemButton>

                        {
                            rota.children?.map((child: any, i: number) => (
                                <Collapse key={i} in={openSubItem[rota.label]} timeout={300} unmountOnExit >
                                    <ListItemButton component={Link} to={child?.to} onClick={() => { props?.clickItemMenu && props.clickItemMenu('') }} dense>
                                        <BsListItemText label={child.label} sx={{ ml: 4 }} />
                                    </ListItemButton>
                                </Collapse>
                            ))
                        }

                        {(index !== rotas.length - 1) && <BsDivider />}

                    </React.Fragment>

                ))}
            </List>

            <Divider style={{ backgroundColor: COLOR_THEME_FONT }} />

            <RotasExtras />

            <BsFooterCopyright>
                <BsDivider />
                <Typography sx={{ fontSize: 11, mt: '10px' }}>
                    {'© Copyright BITSAFIRA ' + moment().year() + '.'} <br />
                    {'Todos os direitos reservados.'}
                </Typography>
            </BsFooterCopyright>

            <RenovacaoPainel
                ref={renovarref}
                dados={{
                    master: auth.masterId,
                    usuario: auth.usuarioId
                }}
            />
        </>
    )
}