import React, { useRef } from 'react';
import { BsTelaPadrao, BsColumns } from '../../../components/BsTelaPadrao';
import { MODEL_CLIENTE, MODEL_HISTORICO_RENOVACOES } from '../../../models';
import { formatedDateTime, formatedPrice, toastError, toastSuccess } from '../../../utils';
import { FaWhatsapp } from "react-icons/fa";
import { useAuth } from '../../../contexts/AuthProvider/useAuth';

export const Renovacoes = () => {
    const auth = useAuth();

    const dataTableRef = useRef<any>();

    const functionsGrid: Array<any> = [
        { icon: <FaWhatsapp />, color: '#0FC144', tooltip: 'Enviar 2ª Via do Recibo', onClick: (enviarSegundaVia) }
    ]

    async function enviarSegundaVia(e: any) {
        await auth.requestPost('/clienteRenovacao/enviarRecibo', { contador: e.contador })
            .then((response: any) => toastSuccess(response.data.mensagem))
            .catch((error: any) => toastError(error));
    }

    const cols: Array<BsColumns> = [
        { field: 'cliente.nome', header: 'Cliente', style: { width: window.innerWidth, whiteSpace: 'pre-wrap' } },
        { field: 'dataAnterior', header: 'Expiração Anterior', body: (e: any) => formatedDateTime(e.dataAnterior), style: { width: '175px' } },
        { field: 'dataCadastro', header: 'Data Renovação', body: (e: any) => formatedDateTime(e.dataCadastro), sortable: true, style: { width: '175px' } },
        { field: 'novaData', header: 'Próxima Expiração', body: (e: any) => formatedDateTime(e.novaData), style: { width: '175px' } },
        { field: 'servico.descricao', header: 'Serviço', style: { width: window.innerWidth, whiteSpace: 'pre-wrap' } },
        { field: 'custo', header: 'Custo', body: (e: any) => formatedPrice(e.custo), style: { width: '175px' } },
        { field: 'bonusUtilizado', header: 'Bônus Utilizado', body: (e: any) => formatedPrice(e.bonusUtilizado), style: { width: '175px' } },
        { field: 'desconto', header: 'Desconto', body: (e: any) => formatedPrice(e.desconto), style: { width: '175px' } },
        { field: 'valorRecebido', header: 'Valor Recebido', body: (e: any) => formatedPrice(e.valorRecebido), style: { width: '175px' } },
        { field: 'edit', exportable: false, adicionalFunctions: functionsGrid, style: { minWidth: '4rem', width: '4rem' } }
    ];

    const onFilter = (value: any, filter: string) => {
        return formatedDateTime(value.dataAnterior).toLowerCase().includes(filter.toLowerCase()) ||
            formatedDateTime(value.dataCadastro).toLowerCase().includes(filter.toLowerCase()) ||
            formatedDateTime(value.novaData).toLowerCase().includes(filter.toLowerCase()) ||
            formatedPrice(value.custo).toLowerCase().includes(filter.toLowerCase()) ||
            formatedPrice(value.valorRecebido).toLowerCase().includes(filter.toLowerCase()) ||
            formatedPrice(value.bonusUtilizado).toLowerCase().includes(filter.toLowerCase()) ||
            formatedPrice(value.desconto).toLowerCase().includes(filter.toLowerCase()) ||
            String(value.cliente.nome).toLowerCase().includes(filter.toLowerCase()) ||
            String(value.servico.descricao).toLowerCase().includes(filter.toLowerCase());
    }

    const groupFooter = (data: any) => {
        var filteredRegisters = dataTableRef.current.getRegisters();

        const filteredCliente = filteredRegisters.filter((registro: any) => registro.dataCadastroCurta === data.dataCadastroCurta);
        const totalCusto = filteredCliente.reduce((total: any, registro: any) => total + registro.custo, 0);
        const totalDesconto = filteredCliente.reduce((total: any, registro: any) => total + registro.desconto, 0);
        const totalRecebido = filteredCliente.reduce((total: any, registro: any) => total + registro.valorRecebido, 0);
        const totalLucro = filteredCliente.reduce((total: any, registro: any) => total + registro.lucroBruto, 0);

        return (
            <React.Fragment>
                <td colSpan={9}>
                    <div style={{ fontSize: '13px', width: '100%' }}>
                        <span className="flex justify-content-end font-bold w-full">Total Registros: {filteredCliente.length}</span>
                        <span className="flex justify-content-end font-bold w-full">Total Recebido: {formatedPrice(totalRecebido)}</span>
                        <span className="flex justify-content-end font-bold w-full"> ( - )  Total Custo: {formatedPrice(totalCusto)}</span>
                        {totalDesconto > 0 &&
                            <span className="flex justify-content-end font-bold w-full"> ( - )  Total Desconto: {formatedPrice(totalDesconto)}</span>
                        }
                        <span className="flex justify-content-end font-bold w-full"> ( = )  Lucro: {formatedPrice(totalLucro)}</span>
                    </div>
                </td>

            </React.Fragment >
        )
    }

    const groupHeader = (data: any) => {
        return (
            <div className="flex align-items-center gap-2">
                <span className="font-bold" style={{ fontSize: '14px' }}>Mês: {data.dataCadastroCurta.replaceAll('-', '/')}</span>
            </div>
        )
    }

    const filterInputs: Array<any> = [
        { id: 'dataCadastroInicial', label: 'Renovação Inicial', type: 'date', modeFilter: 'initial', error: false },
        { id: 'dataCadastroFinal', label: 'Renovação Final', type: 'date', modeFilter: 'final', error: false },
        { id: 'cliente', label: 'Cliente', type: 'select', multiple: true, model: MODEL_CLIENTE, showInativos: true }
    ]

    return (
        <div>
            <BsTelaPadrao
                ref={dataTableRef}
                model={MODEL_HISTORICO_RENOVACOES}
                sortcolumn={'dataCadastro'}
                sortcolumnorder={-1}
                columns={cols}
                onFilterRegisters={onFilter}
                groupcolumn={'dataCadastroCurta'}
                groupfooter={groupFooter}
                groupheader={groupHeader}
                filterInputs={filterInputs}
            />
        </div>
    );
}
