import { Grid, Tooltip } from "@mui/material"

import React, { useEffect, useRef, useState } from "react"
import { BsLoadingButton } from "../../../components/BsLoadingButton";
import { toast } from 'react-toastify';
import { CONSULTA_MASTER, SALVAR_MASTER } from "../../../routes_api";
import { MobVersion } from "../../../utils";
import { ButtonEditLogo, InternalPanel, Logo, PanelLogo } from "./styles";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import LOGO_NAO_CADASTRADA from '../../../images/LogoNaoCadastrada.png';
import { Inputs } from "../../../components/Inputs";
import { useAuth } from "../../../contexts/AuthProvider/useAuth";
import { BsCircularLoading } from "../../../components/BsCircularLoading";

export const Master = (props: any) => {
    const auth = useAuth();

    const itemEls = useRef<{ [key: string]: any }>({});

    const logoRef = useRef<any>(null);
    const btSalvarRef = useRef<any>();

    const [master, setMaster] = useState<any>(null);

    function setLoading(value: boolean) {
        btSalvarRef.current?.setLoading(value);

        if (itemEls)
            Object.values(itemEls.current).forEach((elementRef: any) => { elementRef?.setLoading(value) });
    }


    useEffect(() => {
        async function fetchData() {
            await auth.requestGet(CONSULTA_MASTER).then((response: any) => {
                setMaster(response.data.dados);
            }).catch((error: any) => {
                toast.error(error?.response?.data?.mensagem ? error?.response?.data?.mensagem : 'Erro ao concluir a operação.');;
            }).finally(() => setLoading(false));
        }

        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const salvarMaster = () => {
        async function salvar() {
            setLoading(true);

            let _master = {
                ...master,
                nome: itemEls.current['nome'].getValue(),
                whatsapp: itemEls.current['whatsapp'].getValue(),
                email: itemEls.current['email'].getValue()
            };

            try {
                const response: any = await auth.requestPost(SALVAR_MASTER, _master);
                setMaster(_master);
                toast.success(response.data.mensagem);
            } catch (error: any) {
                toast.error(error?.response?.data?.mensagem ? error?.response?.data?.mensagem : 'Erro ao concluir a operação.');;
            }

            setLoading(false);
        }

        if (itemEls.current['nome'].validate() &&
            itemEls.current['whatsapp'].validate() &&
            itemEls.current['email'].validate())
            salvar();
    }

    function changeLogo(e: any) {
        const logo = e.target.files[0];

        if ((logo.type !== "image/png") && (logo.type !== "image/jpeg") && (logo.type !== "image/jpg")) {
            toast.error('Selecione apenas arquivos .png, .jpeg ou .jpg!')
            return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(logo);
        reader.onload = () => {
            if (reader.result) {
                const base64 = String(reader.result).split(',')[1];

                let _master = { ...master };
                _master.logo = base64;
                setMaster(_master);
            };
        }
    }

    const inputs = [
        { id: 'contador', type: 'text', label: 'Contador', disabled: true, xs: 1 },
        { id: 'nome', type: 'text', label: 'Nome', maxLength: 100, required: true, xs: 10 },
        { id: 'whatsapp', type: 'text', label: 'WhatsApp', maxLength: 20, required: true, xs: 2.25 },
        { id: 'email', type: 'text', label: 'E-mail', maxLength: 50, required: true, xs: 2.25 },
        { id: 'dataExpiracao', type: 'datetime-local', label: 'Data Expiração', disabled: true, xs: 2.25 },
        { id: 'valorSaque', type: 'number', label: 'Valor Saque', disabled: true, xs: 2.25 }
    ]

    return (
        <div style={{ padding: 10 }}>
            {!master ?
                <BsCircularLoading width={'70px'} title={'Carregando Dados do Master...'} /> :

                <React.Fragment>
                    <Grid container spacing={2}>
                        <Grid item xs={MobVersion() ? 12 : 2}>
                            <PanelLogo>
                                <InternalPanel>
                                    <Logo src={master?.logo ? 'data:image/png;base64,' + master?.logo : LOGO_NAO_CADASTRADA} />
                                </InternalPanel>
                                <Tooltip title="Cadastrar/Alterar logo">
                                    <ButtonEditLogo onClick={() => logoRef.current?.click()} size='small' sx={{ position: 'absolute' }}>
                                        <input ref={logoRef} type='file' accept='image/png, image/jpeg, image/jpg' style={{ display: 'none' }} onChange={(e: any) => changeLogo(e)} />
                                        <ModeEditIcon style={{ fontSize: 22 }} />
                                    </ButtonEditLogo>
                                </Tooltip>
                            </PanelLogo>
                        </Grid>

                        <Grid item xs={MobVersion() ? 12 : 10}>
                            <Grid container spacing={3} sx={{ mt: 1 }}>
                                {
                                    inputs.map((input: any, i: number) => (
                                        <Grid key={i} item xs={MobVersion() ? 12 : input.xs}>
                                            <Inputs
                                                ref={(e: any) => itemEls.current[input.id] = e}
                                                inp={input}
                                                submitted={true}
                                                register={master}
                                            />
                                        </Grid>
                                    ))
                                }
                                <Grid item sx={{ mt: 0.5 }} xs={window.innerWidth > 800 ? 2 : 12}>
                                    <BsLoadingButton ref={btSalvarRef} fullWidth variant="contained" onClick={salvarMaster}>Salvar</BsLoadingButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </React.Fragment>
            }
        </div >
    )
}