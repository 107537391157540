import { Grid } from "@mui/material"
import React, { useImperativeHandle, useRef, useState } from "react";
import { toast } from 'react-toastify';
import { useAuth } from "../../../contexts/AuthProvider/useAuth";
import { RELEMBRAR_ACESSO } from "../../../routes_api";
import { Modal } from "../../../new_components/Modal";
import { Input } from "../../../new_components/Input";
import { Form } from "react-bootstrap";
import { MdOutlineSyncLock } from "react-icons/md";

export const RelembrarAcesso = React.forwardRef((props: any, ref) => {
    const auth = useAuth();

    const [validated, setValidated] = useState<any>(false);
    const refmodal = useRef<any>();
    const whatsappref = useRef<any>();

    function openModal() {
        setValidated(false);
        refmodal.current.openModal()
    };

    useImperativeHandle(ref, () => ({
        openModal
    }));

    async function recuperarSenha() {
        try {
            const response: any = await auth.requestPost(RELEMBRAR_ACESSO, { whatsapp: whatsappref.current.getValue() });
            refmodal.current.closeModal();
            toast.success(response.data.mensagem);
        } catch (error: any) {
            toast.error(error?.response?.data?.mensagem ? error?.response?.data?.mensagem : 'Erro ao concluir a operação.');
        }
    }

    const recuperarSenhaClick = (e: any) => {
        setValidated(true);
        const form = e.currentTarget;

        if (form.checkValidity() === true)
            recuperarSenha();

        e.preventDefault();
        e.stopPropagation();
    };

    return (
        <Modal ref={refmodal} title='Recuperar Acesso' maxWidth='xs' submit="form-recover-password" labelSubmit="RECUPERAR" icon={<MdOutlineSyncLock style={{ fontSize: '24px' }} />}>
            <Form id="form-recover-password" noValidate validated={validated} className="w-100" onSubmit={recuperarSenhaClick}>
                <Grid container spacing={2} >
                    <Grid item xs={12}>
                        <Input
                            ref={whatsappref}
                            validated={validated}
                            id="whatsapp"
                            type="text"
                            label="WhatsApp"
                            model={'phone'}
                            required
                            defaultValue={'+55'}
                            placeholder={'+55 99 9999-9999'}
                        />
                    </Grid>
                </Grid>
            </Form>
        </Modal >

    )
})