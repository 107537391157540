import { createGlobalStyle } from 'styled-components';

export const COLOR_THEME = '#032f64';
export const COLOR_THEME_FONT = '#fff';
export const colorOk = '#66CDAA';
export const colorError = '#ff4444';
export const colorWarning = '#e8b407';

const GlobalStyle = createGlobalStyle`

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    width: 100vw;
    height: 100vh;
    background-color: #E5EEFF;
}

`
export default GlobalStyle;