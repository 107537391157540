import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";
import InputGroup from 'react-bootstrap/InputGroup';
import Col from 'react-bootstrap/Col';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import InputGroupText from "react-bootstrap/esm/InputGroupText";
import { FaSearch } from "react-icons/fa";
import "./styles.css";
import { Button } from "../Button";
import { FormFeedback, FormLabel, GroupText } from "../Controls";
import { cpfIsValid, emailIsValid, encrypt } from "../../utils";

const TextFixed = styled(InputGroup.Text)`
`

interface InputProps {
    id: any;
    type: any;
    label?: String;
    placeholder?: any;
    defaultValue?: any;
    feedback?: String;
    textfixed?: String;
    required?: any;
    md?: String;
    ref?: any;
    size?: any;
    onAfterChange?: any;
    search?: any;
    disabled?: any;
    model?: any;
    validated?: any;
    textarea?: any;
    rows?: any;
    maxLength?: any;
    simbolstart?: any;
    rejectSpace?: any;
}

export const Input: React.FC<InputProps> = forwardRef((props: InputProps, ref: any) => {
    const inputRef = useRef<any>();
    const [value, setValue] = useState<any>(props.defaultValue ? props.defaultValue : '');
    const [showPassword, setShowPassword] = useState<any>();
    const [error, setError] = useState<any>(false);

    function getValue() {
        return props.type === 'password' ? encrypt(value) : value;
    }

    function getId() {
        return props.id;
    }

    function validate(_value?: any) {
        console.log('input_validate=' + props.id)
        _value = _value ? _value : value;

        if (!_value && !props.required)
            return true

        var result = props.required ? (_value !== '') : true;

        if (result && props.model) {
            if (props.model === 'phone')
                result = _value.length >= 16;
            else if (props.model === 'cpf')
                result = cpfIsValid(_value);
            else if (props.model === 'email')
                result = emailIsValid(_value)

            inputRef.current.setCustomValidity(result ? '' : 'Invalid field')
        }

        return result
    }

    useImperativeHandle(ref, () => ({
        getValue,
        setValue,
        setError,
        getId,
        validate
    }));

    useEffect(() => {
        if (props.defaultValue && (props.model === 'phone'))
            validate();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.defaultValue])

    function applyCustomMask(_value: string, mask: any): string {
        if (!mask)
            return _value;

        let result = '';
        let maskIndex = 0;

        for (let i = 0; i < _value.length; i++) {
            const char = _value[i];

            if (maskIndex >= mask.length) {
                break;
            }

            if (/\d/.test(char)) {
                while (maskIndex < mask.length && (mask[maskIndex] === ' ' || mask[maskIndex] === '-' || mask[maskIndex] === '.' || mask[maskIndex] === '+F')) {
                    result += mask[maskIndex];
                    maskIndex++;
                }

                if (mask[maskIndex] === '9') {
                    result += char;
                    maskIndex++;
                }
            } else if (mask[maskIndex] === char) {
                result += char;
                maskIndex++;
            }
        }

        return result;
    }

    function validateModel() {
        if ((props.model === 'phone') && (value.length < 16))
            return 'Telefone inválido'
        else if ((props.model === 'cpf') && (!cpfIsValid(value)))
            return 'CPF inválido'
        else if ((props.model === 'email') && (!emailIsValid(value)))
            return 'E-mail inválido'
    }

    function getModel(_value: any) {
        if (props.model) {
            if (props.model === 'phone') {
                if (_value.length <= 16)
                    return '+99 99 9999-9999'
                else
                    return '+99 99 99999-9999'
            }
            else if (props.model === 'cpf')
                return '999.999.999-99'
            else if (props.model === 'cep')
                return '99999-999'
        } else
            return ''
    }

    function onChange(e: any) {
        var _value = e.target.value;

        if (props.rejectSpace || (props.model === 'email') || (props.type === 'password'))
            _value = _value.replace(/\s+/g, "");

        if ((props.model === 'phone') && !_value)
            _value = '+';

        const _maskedValue = applyCustomMask(_value, getModel(_value));

        setValue(_maskedValue);

        if (props.onAfterChange)
            props.onAfterChange(_maskedValue);

        validate(_value)
    }

    return (
        <Form.Group as={Col} md={props?.md ? props.md : "12"} controlId={props.id}>
            {props.label && <FormLabel>{(props?.required ? '*' : '') + props.label}</FormLabel>}
            <InputGroup size={props.size ? props.size : "sm"} hasValidation>
                {props.simbolstart && <GroupText>{props.simbolstart}</GroupText>}
                <Form.Control
                    ref={inputRef}
                    as={props.textarea && 'textarea'}
                    name={props.id}
                    type={props.type !== 'password' ? props.type : showPassword ? 'text' : 'password'}
                    placeholder={props.placeholder}
                    onChange={onChange}
                    required={props.required}
                    isInvalid={error}
                    autoComplete="off"
                    disabled={props.disabled}
                    value={value}
                    rows={props.rows}
                    maxLength={props.maxLength}
                    style={{
                        textAlign: (props.type === 'number') ? 'right' : 'left'
                    }}
                />

                {props.search &&
                    <InputGroupText>
                        <FaSearch />
                    </InputGroupText>
                }

                {props.type === 'password' &&
                    <Button
                        size={props.size ? props.size : "sm"}
                        style={{
                            display: 'flex',
                            borderTopRightRadius: '4px',
                            borderBottomRightRadius: '4px',
                            fontSize: '0.875rem',
                        }}
                        onClick={() => setShowPassword(!showPassword)}
                    >
                        {showPassword ? <VisibilityOffIcon style={{ fontSize: "1.2rem" }} /> : <VisibilityIcon style={{ fontSize: "1.2rem" }} />}
                    </Button>
                }

                <FormFeedback type="invalid">
                    {
                        (props.model && props.validated && value) ? validateModel() :
                            !error && (props.feedback ? props.feedback : props.required ? 'Campo obrigatório.' : '')
                    }
                </FormFeedback>

                {props.textfixed &&
                    <TextFixed id="basic-addon2">
                        {props.textfixed}
                    </TextFixed>}
            </InputGroup>
        </Form.Group>
    );
});
