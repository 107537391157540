import { Typography } from "@mui/material";

export const InstrucoesTemplateMensagem = (tipo: any) => {
    return (
        <div style={{ marginTop: '-15px', border: '1px solid gray', borderRadius: '5px', padding: '10px' }}>
            <Typography variant="button" display="block">
                {'INSTRUÇÕES:'}
            </Typography>
            <Typography style={{ fontSize: 12 }} gutterBottom>
                {'Customize como desejar as mensagens de envio. Quando utilizado alguma das variáveis disponíveis a plataforma substituirá pelo valor referente a variável.'}
                <br />
            </Typography>
            <Typography variant="button" display="block">
                {'EXEMPLO:'}
            </Typography>
            <Typography style={{ fontSize: 12 }} gutterBottom>
                {'Desejo colocar na mensagem o nome do meu cliente. Dessa forma preciso apenas adicionar {nome} onde eu desejar que apareça.'}
                <br />
                {'- Mensagem Padrão: Olá {nome}!'}
                <br />
                {'- Como ficará: Olá Bitsafira!'}
                <br />
            </Typography>
            <Typography variant="button" display="block">
                {'VARIÁVEIS DISPONÍVEIS:'}
            </Typography>
            <Typography style={{ fontSize: 12 }} gutterBottom>
                <span style={{ fontWeight: 500 }}>{'Cobrança Cliente, Após Vencimento, Personalizada:'}</span>
                <br />
                {`{nome}, {primeiroNome}, {valor}, {servico}, {valorLiquido}, {tipoServico}, {observacao}, {observacaoServico}, {login}, {senha}, {saudacao}, {dataExpiracao}, {dataExpiracaoCompleta}, {bonus}, {dataAtual}, {linkPagamento}`}
                <br /><br />
                <span style={{ fontWeight: 500 }}>{'Recibo Cliente:'}</span>
                <br />
                {`{nome}, {primeiroNome}, {valor}, {servico}, {quantidade}, {valorTotal}, {tipoServico}, {observacao}, {observacaoServico}, {login}, {senha}, {saudacao}, {dataExpiracao}, {dataExpiracaoCompleta}, {bonus}, {dataAtual}, {valorRecebido}, {descontoAplicado}, {bonusUtilizado}`}
                <br /><br />
                <span style={{ fontWeight: 500 }}>{'Lista Transmissão:'}</span>
                <br />
                {`{descricao}, {primeiraDescricao}, {saudacao}, {whatsapp}`}
            </Typography>
        </div>
    );
};
