import { Grid } from "@mui/material"

import React, { useEffect, useRef, useState } from "react"
import { BsLoadingButton } from "../../../components/BsLoadingButton";
import { toast } from 'react-toastify';
import { getUserLocalStorage } from "../../../contexts/AuthProvider/util";
import { CONSULTA_USUARIO, SALVAR_USUARIO } from "../../../routes_api";
import { AlterarSenha } from "./AlterarSenha";
import { useAuth } from "../../../contexts/AuthProvider/useAuth";
import { BsCircularLoading } from "../../../components/BsCircularLoading";
import { MobVersion, encrypt } from "../../../utils";
import { Inputs } from "../../../components/Inputs";

export const Usuario = (props: any) => {
    const auth = useAuth();


    const itemEls = useRef<{ [key: string]: any }>({});
    const alterarSenhaRef = useRef<any>();
    const btSalvarRef = useRef<any>();
    const btAlterarSenhaRef = useRef<any>();

    const [usuario, setUsuario] = useState<any>();

    function setLoading(value: boolean) {
        btSalvarRef.current?.setLoading(value);
        btAlterarSenhaRef.current?.setLoading(value);

        if (itemEls)
            Object.values(itemEls.current).forEach((elementRef: any) => { elementRef?.setLoading(value) });
    }

    useEffect(() => {
        async function fetchData() {
            await auth.requestGet(CONSULTA_USUARIO).then((response: any) => {
                setUsuario(response.data.dados);
            }).catch((error: any) => {
                toast.error(error?.response?.data?.mensagem ? error?.response?.data?.mensagem : 'Erro ao concluir a operação.');;
            }).finally(() => setLoading(false));
        }

        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function salvar(_usuario: any) {
        setLoading(true);

        try {
            const response: any = await auth.requestPost(SALVAR_USUARIO, _usuario);

            auth.name = _usuario.nome;

            const credentials = {
                ...getUserLocalStorage(),
                token: response.data.dados.token,
                name: _usuario.nome
            }

            auth.alterUser(credentials)

            setUsuario(_usuario);
            toast.success(response.data.mensagem);
        } catch (error: any) {
            toast.error(error?.response?.data?.mensagem ? error?.response?.data?.mensagem : 'Erro ao concluir a operação.');;
        }

        setLoading(false);
    }

    const salvarUsuario = () => {
        if (itemEls.current['login'].validate() && itemEls.current['nome'].validate()) {
            const _usuario = {
                ...usuario,
                login: itemEls.current['login'].getValue(),
                nome: itemEls.current['nome'].getValue()
            }

            salvar(_usuario)
        }
    }

    const alterarSenha = (novaSenha: string) => {
        let _usuario = {
            ...usuario,
            senha: encrypt(novaSenha)
        }

        salvar(_usuario);
        alterarSenhaRef.current.closeModal();
    }

    const inputs = [
        { id: 'login', type: 'text', label: 'Login', maxLength: 15, required: true, xs: 3 },
        { id: 'nome', type: 'text', label: 'Nome', maxLength: 100, required: true, xs: 3 }
    ]

    return (
        <React.Fragment>
            {!usuario ?
                <BsCircularLoading width={'70px'} title={'Carregando Dados do Usuário...'} /> :
                <div style={{ padding: 10 }}>
                    <Grid container spacing={2}>
                        {
                            inputs.map((input: any, i: number) => (
                                <Grid key={i} item xs={MobVersion() ? 12 : input.xs}>
                                    <Inputs ref={(e: any) => itemEls.current[input.id] = e} inp={input} submitted={true} register={usuario} />
                                </Grid>
                            ))
                        }
                        <Grid item sx={{ mt: 0.5 }} xs={window.innerWidth > 800 ? 2 : 12}>
                            <BsLoadingButton ref={btSalvarRef} fullWidth onClick={salvarUsuario}>Salvar</BsLoadingButton>
                        </Grid>
                        <Grid item sx={{ mt: 0.5 }} xs={window.innerWidth > 800 ? 2 : 12}>
                            <BsLoadingButton ref={btAlterarSenhaRef} fullWidth onClick={() => alterarSenhaRef.current.openModal()}>Alterar Senha</BsLoadingButton>
                        </Grid>
                    </Grid >
                </div>
            }

            <AlterarSenha
                ref={alterarSenhaRef}
                senhaAtual={usuario?.senha ? usuario.senha : ''}
                onFinish={alterarSenha}
            />
        </React.Fragment>
    )
}