import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { Modal } from "../../../new_components/Modal";
import { PlanosRenovacao } from "../../PlanosRenovacao";

export const PlanosRenovacaoModal = forwardRef((props: any, ref) => {
    const refmodal = useRef<any>();

    const [data, setData] = useState<any>();

    function openModal(_data: any) {
        setData(_data);
        refmodal.current.openModal();
    }

    useImperativeHandle(ref, () => ({
        openModal
    }));

    return (
        <Modal ref={refmodal} title='Planos de Renovação' maxWidth={'lg'} >
            <PlanosRenovacao data={data} />
        </Modal >
    )
})