import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from ".";
import { PaginaIndisponivel } from "../../pages/Outras/Indisponivel";
import { Login } from "../../pages/Login";

export const PRIVILEGIO_ADM = [999];
export const PRIVILEGIO_REVENDA = [2, 4, 999];
export const PRIVILEGIO_NORMAL = [1, 2, 4, 999];

export const RequireAuth = ({ children }: { children: JSX.Element }) => {
    const auth = useContext(AuthContext);
    const navigate = useNavigate();

    if (!auth.token) {
        navigate('/login')
        return <Login />
    }

    return children;
}

export const RequireAuthNormal = ({ children }: { children: JSX.Element }) => {
    const auth = useContext(AuthContext);
    const navigate = useNavigate();

    if (!auth.token) {
        navigate('/login')
        return <Login />
    } else if (!PRIVILEGIO_NORMAL.includes(Number(auth.privilegio))) {
        return <PaginaIndisponivel />
    }

    return children;
}

export const RequireAuthRevenda = ({ children }: { children: JSX.Element }) => {
    const auth = useContext(AuthContext);
    const navigate = useNavigate();

    if (!auth.token) {
        navigate('/login')
        return <Login />
    } else if (!PRIVILEGIO_REVENDA.includes(Number(auth.privilegio))) {
        return <PaginaIndisponivel />
    }

    return children;
}

export const RequireAuthAdm = ({ children }: { children: JSX.Element }) => {
    const auth = useContext(AuthContext);
    const navigate = useNavigate();

    if (!auth.token) {
        navigate('/login')
        return <Login />
    } else if (!PRIVILEGIO_ADM.includes(Number(auth.privilegio))) {
        return <PaginaIndisponivel />
    }

    return children;
}