import { useRef } from 'react';
import { BsColumns, BsTelaPadrao } from '../../../components/BsTelaPadrao';
import { formatedTelefone } from '../../../utils';
import { EXCLUIR_REVENDA } from '../../../routes_api';
import { MODEL_REVENDA_ADM } from '../../../models';

export const Revendas = () => {
    const dataTableRef = useRef<any>(null);

    const cols: Array<BsColumns> = [
        { field: 'contador', header: 'Código', sortable: true, style: { width: '100px' } },
        { field: 'nome', header: 'Descrição', sortable: true },
        { field: 'whatsapp', header: 'WhatsApp', sortable: true, body: (e) => formatedTelefone(e.whatsapp), style: { width: '125px' } },
        { field: 'edit', exportable: false, style: { minWidth: '6rem', width: '6rem' } }
    ];

    const onFilter = (value: any, filter: string) => {
        return String(value.whatsapp).toLowerCase().includes(filter.toLowerCase()) ||
            formatedTelefone(value.whatsapp).toLowerCase().includes(filter.toLowerCase()) ||
            String(value.contador).toLowerCase().includes(filter.toLowerCase()) ||
            String(value.nome).toLowerCase().includes(filter.toLowerCase());
    }

    return (
        <BsTelaPadrao
            ref={dataTableRef}
            model={MODEL_REVENDA_ADM}
            prefixApiDelete={EXCLUIR_REVENDA}
            sortcolumn={'nome'}
            sortcolumnorder={1}
            columns={cols}
            onFilterRegisters={onFilter}
            disabledEdit
        />
    );
}
