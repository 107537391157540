import { useAuth } from '../../contexts/AuthProvider/useAuth'
import { Link } from 'react-router-dom'
import { useRef, useState } from 'react'
import { Grid } from '@mui/material'
import Logo from '../../icones/Logo.png'
import { COLOR_THEME } from '../../styles/theme'
import { MobVersion } from '../../utils'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { Card, Form, Row } from "react-bootstrap"
import { Input } from '../../new_components/Input'
import { Button } from '../../new_components/Button'
import LoginIcon from '@mui/icons-material/Login';
import { confirm } from '../../new_components/Confirmation'
import { RelembrarAcesso } from './RelembrarAcesso'
import { TermosUso } from '../Outras/TermosUso'
import { PlanosRenovacaoModal } from '../Comuns/PlanosRenovacaoModal'

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    min-width: 100%;
`
export const Wrapper = styled.div`
    display: flex;
    padding: 2rem;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    max-width: ${MobVersion() ? '100%' : '350px'};
    max-height: ${MobVersion() ? '100%' : '650px'};
    box-shadow: rgba(166, 187, 255, 0.3) 0px 0px 15px 2px;
    background-color: #fff;
`
export const LogoPanel = styled.img`
    width: 300px;
    padding: 0 4rem;
`

export const LinkLabel = (props: any) => {
  return (
    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', paddingTop: 3 }}>
      <Link style={{ textDecoration: 'none', color: COLOR_THEME, fontSize: '14px', fontWeight: 500 }} to={props.to} onClick={props.onClick}>{props.label}</Link>
    </Grid>
  )
}

export const Login = () => {
  const auth = useAuth();

  const usernameRef = useRef<any>();
  const passwordRef = useRef<any>();
  const planosref = useRef<any>();
  const relembrarAcessoRef = useRef<any>();
  const termosRef = useRef<any>();

  const [validated, setValidated] = useState(false);

  async function authenticate() {
    var username = usernameRef.current.getValue();
    var password = passwordRef.current.getValue();


    try {
      await auth.authenticate(username, password)
    } catch (error: any) {
      if (error.response.data.status === 406) {

        if (await confirm('Usuário Bloqueado! Deseja realizar a renovação agora?'))
          planosref.current.openModal(error.response.data.dados);

      } else if (error.code === 'ERR_NETWORK')
        toast.error('Erro de Conexão.')
      else
        toast.error(error?.response?.data?.mensagem ? error?.response?.data?.mensagem : 'Erro ao concluir a operação.');
    }
  }

  const handleSubmit = (e: any) => {
    const form = e.currentTarget;

    if (form.checkValidity() === true)
      authenticate();

    else setValidated(true);

    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <>
      <Container>
        <Wrapper>
          <Form noValidate validated={validated} className="w-100" onSubmit={handleSubmit}>
            <Row className="mb-2 pt-4 justify-content-center">
              <LogoPanel src={Logo} />
            </Row>
            <Row className="justify-content-center text-center">
              <Card className="border-0 p-0">
                <Card.Body>
                  <Card.Title>BEM-VINDO</Card.Title>
                  <Card.Text>Preencha os dados de login para acessar</Card.Text>
                </Card.Body>
              </Card>
            </Row>
            <Row className="mb-2 justify-content-center">
              <Input ref={usernameRef} id="username" type="text" label="Usuário" required />
            </Row>
            <Row className="mb-4 justify-content-center">
              <Input ref={passwordRef} id="password" type="password" label="Senha" required />
            </Row>
            <Row className="mb-2 justify-content-end m-0">
              <Button type="submit" icon={<LoginIcon />}>Acessar</Button>
            </Row>
            <Row className="text-left">
              <div style={{ fontSize: '14px' }}>Não tem uma conta?
                <Link style={{ textDecoration: 'none', color: COLOR_THEME, fontWeight: 500 }} to='/register'>{' Cadastre-se'}</Link>
              </div>
            </Row>
            <Row className="mt-2 justify-content-end m-0">
              <LinkLabel label='Esqueceu a senha?' onClick={() => relembrarAcessoRef.current.openModal()} />
              <LinkLabel label='Termos de uso' onClick={() => termosRef.current.openModal()} />
            </Row>
          </Form>
        </Wrapper>
      </Container >
      <PlanosRenovacaoModal ref={planosref} />
      <RelembrarAcesso ref={relembrarAcessoRef} />
      <TermosUso ref={termosRef} />
    </>
  )
}