import { toast } from 'react-toastify';
import { BsTelaPadrao, BsColumns } from '../../../components/BsTelaPadrao';
import { useAuth } from '../../../contexts/AuthProvider/useAuth';
import { model_historico_indicacoes } from '../../../models';
import { formatedDateTimeSeconds, formatedTelefone, labelSituacaoIndicacao } from '../../../utils';
import copy from "copy-to-clipboard";

export const Indicacoes = () => {
    const auth = useAuth();

    const cols: Array<BsColumns> = [
        { field: 'dataCadastro', header: 'Data', body: (e) => formatedDateTimeSeconds(e.dataCadastro), sortable: true, style: { width: '175px' } },
        { field: 'masterDestino.nome', header: 'Indicação', sortable: true, style: { width: window.innerWidth, whiteSpace: 'pre-wrap' } },
        { field: 'masterDestino.whatsapp', header: 'Whatsapp', sortable: true, body: (e: any) => formatedTelefone(e.masterDestino.whatsapp), style: { width: '150px' } },
        { field: 'situacao', header: 'Situação', body: (e: any) => labelSituacaoIndicacao(e.situacao), style: { width: '100px' } },
    ];

    const onFilter = (value: any, filter: string) => {
        return formatedDateTimeSeconds(value.dataCadastro).toLowerCase().includes(filter.toLowerCase()) ||
            String(value.masterDestino.nome).toLowerCase().includes(filter.toLowerCase()) ||
            formatedTelefone(value.masterDestino.whatsapp).toLowerCase().includes(filter.toLowerCase());
    }

    const functions: Array<any> = [
        {
            icon: 'pi pi-share-alt',
            label: 'Copiar Link de Indicação',
            onClick: () => {
                copy('https://app.bitsafira.com.br/register?masterid=' + auth.masterId);
                toast.success('Link de Indicação copiado com sucesso.')
            }
        }
    ]

    return (
        <div>
            <BsTelaPadrao
                model={model_historico_indicacoes}
                sortcolumn={'dataCadastro'}
                sortcolumnorder={-1}
                columns={cols}
                onFilterRegisters={onFilter}
                adicionalFunctions={functions}
            />
        </div>
    );
}
