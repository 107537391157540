import { useRef } from 'react';
import { BsTelaPadrao, BsColumns } from '../../../components/BsTelaPadrao';
import { formatedDateTime, labelAtivoInativo, formatedTelefone, intToAtivoInativo } from '../../../utils';
import { MODEL_REVENDA_MASTER } from '../../../models';

export const Master = () => {
    const dataTableRef = useRef<any>(null);

    const cols: Array<BsColumns> = [
        { field: 'contador', header: 'Código', sortable: true, style: { width: '100px' } },
        { field: 'nome', header: 'Nome', sortable: true, style: { width: window.innerWidth, whiteSpace: 'pre-wrap' } },
        { field: 'dataExpiracao', header: 'Expiração', sortable: true, body: (e: any) => formatedDateTime(e.dataExpiracao), style: { width: '125px' } },
        { field: 'email', header: 'E-mail', sortable: true, style: { width: '125px' } },
        { field: 'whatsapp', header: 'WhatsApp', sortable: true, body: (e: any) => formatedTelefone(e.whatsapp), style: { width: '125px' } },
        { field: 'ativo', header: 'Situação', body: (e: any) => labelAtivoInativo(e.ativo), style: { width: '75px' } }
    ];

    const onFilter = (value: any, filter: string) => {
        return intToAtivoInativo(value.ativo).toLowerCase().includes(filter.toLowerCase()) ||
            formatedDateTime(value.dataExpiracao).toLowerCase().includes(filter.toLowerCase()) ||
            String(value.whatsapp).toLowerCase().includes(filter.toLowerCase()) ||
            formatedTelefone(value.whatsapp).toLowerCase().includes(filter.toLowerCase()) ||
            String(value.contador).toLowerCase().includes(filter.toLowerCase()) ||
            String(value.nome).toLowerCase().includes(filter.toLowerCase()) ||
            String(value.email).toLowerCase().includes(filter.toLowerCase());
    }

    return (
        <div>
            <BsTelaPadrao
                ref={dataTableRef}
                model={MODEL_REVENDA_MASTER}
                module={'Masters'}
                sortcolumn={'dataExpiracao'}
                sortcolumnorder={1}
                columns={cols}
                onFilterRegisters={onFilter}
            />
        </div>
    );
}
